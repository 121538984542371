.swiper {
  width: 100%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 50%;
  object-fit: cover;
}
.swiper-button-next, .swiper-rtl ,.swiper-button-prev {
  color: black;
  opacity: 20%;
}
.carrouseltitle{ 
  position: absolute;
  top: 3%;
  padding: 1em;
  text-align: center;
  width: 100%;
  font-size: 2.5em;
  font-weight: bold;
  color: indigo;
  z-index: 1;
}
.carrouselabo{ 
  position: absolute;
  top: 20%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;
}
.carrouselbuttonmodel{ 
  position: absolute;
  bottom: 15%;
  text-align: center;
  font-size: 2vh;
  font-weight: 500;
  z-index: 1;
}
.carrouseltitlemodel{ 
  text-align: center;
  font-size: 2vh;
  
}
:root {
  --swiper-theme-color: indigo;
  /*
  --swiper-preloader-color: var(--swiper-theme-color);
  --swiper-wrapper-transition-timing-function: initial;
  */
}
@media screen and (max-width: 768px) {
  .swiper-slide img {
    width: 75%;
  }
  .swiper-button-next, .swiper-rtl ,.swiper-button-prev {
    color: black;
    opacity: 20%;
  }
  .carrouseltitle{ 
    font-size: 4vh;
    top: 1%;
  }
  .carrouselabo{ 
    top: 20%;
  }
}