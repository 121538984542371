.contact{
    background-color: rgb(238, 238, 238);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;
    padding: 2em;
    margin-bottom: 3em;
}
.titrecontact{
    font-weight: bold;
    font-size: 2em;
    text-align: center;
}
.textecontact{
    font-size: 20px;
    margin: 1.5em;
    text-align: center;
}
.callimg{
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    height: 150px;
    object-fit: contain;
}
.numcontact{
    font-weight: bolder;
    font-size: 2em;
    text-align: center;
}