.entreprisepart{
    width: 100%;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    padding: 2em;
    text-align: center;
}
.entreprisetext{
    font-size: 20px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    text-align: start;
}
.entreprisetext2{
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
}
.entreprisecar{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    height: 25em;
    object-fit: contain;
}
.video {
    position: absolute;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}
.videotitle{ 
    position: absolute;
    bottom: 2em;
    text-align: center;
    width: 100%;
    font-size: 6vh;
    font-weight: bold;
    color: white;
    text-shadow: 1px 1px 5px purple;
    z-index: 1;
}
@media screen and (max-width: 768px) {
.entreprisecar{
    height: 10em;
    margin-top: 30px;
}
.entreprisetext{
    width: 90%;
}
}