.presentationvehicule{
    padding: 5%;
    height: 100%;
}
.nommodele{
    font-size: 5vh;
    font-weight: bold;
    padding-right: 40px;
}
.textemodele{
    font-size: 3vh;
}
.titremodele{
    font-size: 4vh;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 1em;
}
.infostarifs{
    background: linear-gradient(#f4adff,#fafafa);
    border-radius: 30px;
    text-align: center;
    height: min-content;
    padding: 10%;
    margin-top: 10vh;
    margin-bottom: 20%;
}
.accordeontext{
    text-align: justify;
}
.titreinfostarifs{
    font-size: 40px;
    font-weight: bolder;
}
.titreinfostarifs2{
    font-size: 25px;
    font-weight: 400;
}
.specsinfostarifs{
    font-size: 10px;
}
.btnabonnement{
    margin-top: 15%;
    margin-bottom: 10%;
    width: 100%;
    height: 50px;
    font-size: 20px;
}
.imgmodele{
    width: 90%;
    height: 30vh;
    object-fit: contain;
    margin-top: 10%;
    margin-bottom: 10%;
}
.specvehicules{
    background-color: black;
    padding: 5%;
}
.btnabonnement2{
    display: flex;
    margin-left: auto;
    margin-right: auto;
}
.visiophoto{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}
.modalmodele{
    max-width: 85%;
}
.modeleSwiper{
    height: 80vh;
}
.modeleirl{
    border-radius: 20px;
    height: 90%;
}
  @media screen and (max-width: 1000px) {
    .nommodele{
        font-size: 4vh;
    }
    .imgmodele{
        width: 80%;
    }
    .modeleirl{
        height: 80%;
    }
  }
@media screen and (max-width: 640px) {
    .titremodele{
        text-align: center;
    }
    .imgmodele{
        margin: 0%;
        margin-top: 5%;
        margin-bottom: 5%;
        width: 100%;
    }
    .infostarifs{
        margin-top: 5%;
        margin-bottom: 5%;
        padding: 5%;
    }
    .btnabonnement{
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .modalmodele{
        max-width: 100%;
    }
    .modeleSwiper{
        height: 80vh;
    }
    .modeleirl{
        border-radius: 0px;
        height: 70%;
    }
  }