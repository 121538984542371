.banner{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  object-fit: cover;
}
.bannertitledzd{
  position: absolute;
  text-align: center;
  bottom: 0%;
  font-size: 3em;
  font-weight: bold;
  color: white;
}
.bannertitle{ 
  position: absolute;
  bottom: 2em;
  text-align: center;
  width: 100%;
  font-size: 6vh;
  font-weight: bold;
  color: white;
  z-index: 1;
}
.discover{
  background-color: black;
  padding: 10%;
}
.discover2{
  background-color: rgb(243, 244, 246);
  padding: 3%;
  padding-left: 10%;
  padding-right: 10%;
}
.new{
  
}
.itemshome{
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.btnhome{
  height: 50px;
  font-size: 17px;
}
.logo{
  max-width: 100px;
}
.vtcprix{
  font-size: 3em; 
  margin-bottom: 3rem; 
  font-weight: bold;
}
.vtclogo{
  position: relative;
  margin: 2em;
  width: 150px;
  object-fit: contain;
}
@media screen and (max-width: 768px) {
  .bannertitle{ 
    font-size: 5vh;
  }
  .discover{
    padding: 7%;
    padding-top: 30vh;
    padding-bottom: 30vh;
  }
  .discover2{
    padding: 3%;
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
  .vtcprix{
    font-size: 2.5em;
  }
  .logo{
    max-width: 80px;
  }
}