.subscribevehicule{
    padding: 5%;
}
.nommodele{
    font-size: 5vh;
    font-weight: bold;
    padding-right: 40px;
}
.textemodele{
    font-size: 3vh;
}
.textemodele2{
    font-size: 2.5vh;
}
.titremodele{
    font-size: 4vh;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 1em;
}
.imgsubscribe{
    border-radius: 20px;
    object-fit: contain;
}
.formsubscribe{
    width: 60%;
    margin-left: 20%;
}
.forminput{
    margin: 3%;
}
.forminput2{
    margin-left: 3%;
}
.btnsubmit{
    font-size: 15px;
    height: 45px;
    width: 250px;
    margin-left: 3%;
}
@media screen and (max-width: 640px) {
    .imgsubscribe{
        margin: 0%;
        margin-top: 10%;
        margin-bottom: 10%;
        width: 100%;
    }
    .formsubscribe{
        width: 100%;
        margin-left: 0%;
    }
    .forminput{
        margin: 0%;
        margin-top: 4%;
        margin-bottom: 4%;
    }
    .forminput2{
        margin-left: 0%;
    }
    .btnsubmit{
        margin-left: 0%;
        width: 100%;
    }
  }