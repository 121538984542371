.fond{
    min-height: 100vh;
}
.cartevehicules{
    position: relative;
    background-color: white;
    margin: 2em;
    width: 270px;
    height: 270px;
    border-radius: 30px;
    box-shadow: 
    0px 8.4px 30.2px rgba(24,24,24, 0.059),
    0px 8.4px 30.2px rgba(24,24,24, 0.059),
    0px 1px 10px rgba(24,24,24, 0.046);
}
.cartevehicules:hover{
  background: linear-gradient(white 60%,white 90%) padding-box,
              linear-gradient(to right, rgb(175, 63, 255), rgb(255, 81, 188)) border-box;
  border-radius: 30px;
  border: 3px solid transparent;
  cursor: pointer;
}
.cardtitrevehicules{
    color: indigo;
    font-size: 20px;
    margin-top: 2em;
}
.cardimgvehicules{
    height: 35%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
}
.combo{
    padding: 1em;
    padding-left: 8%;
    padding-right: 8%;
}
.resetbtn{
    height: 55px;
}
.titrevehicules{
    font-size: 20px;
    padding-bottom: 2em;
    text-align: center;
}
.vehicules{

}
a.cover-link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
.badge{
    background-color: indigo;
    color: white;
    font-size: 15px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 2px 8px;
    text-align: center;
    border-radius: 25px;
}
.badge2{
    background-color: indigo;
    color: white;
    font-size: 15px;
    width: fit-content;
    padding: 2px 8px;
    border-radius: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
}
@media screen and (max-width: 768px) {
  .cartevehicules{
    margin: 3%;
    width: 90%;
    height: 200px;
    border-radius: 20px;
}
.cardtitrevehicules{
    color: indigo;
    font-size: 20px;
    margin-top: 1em;
}
.cardimgvehicules{
    height: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
}
}