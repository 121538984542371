.titre1{
  font-size: 2.5em;
  text-align: center;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.titre2{
  font-size: 2em;
  text-align: center;
  font-weight: bold;
  margin-top: 1.5em;
  margin-bottom: 1em;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.carte{
  position: relative;
  background: #f4adff;
  margin: 2em;
  width: 400px;
  height: 330px;
  border-radius: 25px;
}
.cardtitre{
  color: black;
  font-weight: bold;
}
.bg-degrade{
  background: linear-gradient(90deg,#be22ff 20%,#ff279c 50%,#ff981f 80%);
}
.cardimg{
  height: 45%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}
.checkimg{
  height: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 70%;
  object-fit: contain;
}
.checktext{
  position: relative;
  text-align: justify;
  margin: 2em;
  width: 420px;
}
.avantages{
  min-height: 100vh;
  padding-top: 10vh;
  margin-left: auto;
  margin-right: auto;
}
.carte2{
  background: linear-gradient(rgb(24 24 27), rgb(24 24 27)) padding-box,
  linear-gradient(to right, rgb(175, 63, 255), rgb(255, 81, 188)) border-box;
  border: 3px solid transparent;
  border-radius: 30px;
  margin: 3em;
  height: 400px;
  width: 35%;
  border-radius: 20px;
}
.cardtitre2{
  color: white;
  font-size: 25px;
  font-weight: 600;
  margin-top: 1em;
  padding: 10px;
}
.cardimg2{
  height: 35%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  object-fit: contain;
}
.accordeon{
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  margin-bottom: 2em;
}
.offertitle{ 
  font-size: 6vh;
  font-weight: bold;
  color: white;
  text-shadow: 1px 1px 5px indigo;
  z-index: 1;
}
.offercarte{
  background: rgb(255, 255, 255);
  /*box-shadow: 0px 0px 10px 0px black;*/
  border-radius: 10px;
}
.notif{
  position: absolute;
  top: -10px;
  right: -20px;
  text-align: center;
  padding: 5px 10px;
  border-radius: 25px;
  background-color: indigo;
  color: white;
}
@media screen and (max-width: 768px) {
  .titre1{
    font-size: 2em;
  }
  .checktext{
    margin-top: .5em;
  }
  .cardtitre2{
    font-size: 18px;
  }
  .carte2{
    margin: 2em;
    height: 300px;
    width: 80%;
  }
  .cardimg2{
    height: 30%;
    margin-top: 2rem;
  }
  .carte{
    margin: 1em;
    width: 90%;
    height: 200px;
    border-radius: 25px;
  }
  .cardtitre{
    color: black;
    font-weight: bold;
  }
  .cardimg{
    height: 45%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }
  .notif{
    position: relative;
    top: -10px;
    right: 0px;
    padding: 5px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}